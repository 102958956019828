import { graphql } from "gatsby"
import React from "react"
import {  Col, Container, Row } from "reactstrap"
import {
  Hero,
  Layout,
  Tools,
  CountriesGrid,
  Seo
} from "../../components"
import { getClassFromSlug, stripTags } from "../../lib/utils"
import useCountriesInLanguage from "../../hooks/useCountriesInLanguage"
import america from  "../../images/america-icon.svg"
import africa from  "../../images/africa-icon.svg"
import asia from  "../../images/asia-icon.svg"


const CountryProfilepage = ({ data, pageContext }) => {
  const lang = data?.PageDetails?.edges[0]?.node?.fmmCore?.languageCode
  const slug = data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
  const copyrightMessage = stripTags(
    data?.FooterCopyright?.edges[0]?.node?.content
  )

  return (
    <Layout
      lang={lang}
      languages={data?.Languages?.edges}
      copyrightMessage={copyrightMessage}
      slug={slug}
      className={'CountryProfile'}
    >
      <Seo
        title={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoTitle}
        description={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoDescription}
        lang={lang}
      />
      <Hero
        title={data?.PageDetails?.edges[0]?.node?.hero?.title}
        intro={data?.PageDetails?.edges[0]?.node?.hero?.intro}
        linkTitle={data?.PageDetails?.edges[0]?.node?.hero?.linkLabel}
        linkUrl={data?.PageDetails?.edges[0]?.node?.hero?.linkUrl}
        imageUrl={data?.PageDetails?.edges[0]?.node?.hero?.image?.sourceUrl}
        slug={getClassFromSlug(
          data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
        )}
        lang={lang}
        pageContext={pageContext}
      />

      <section className="countries-page">
        <Container>
          <Row>
            <section className="col-12 col-lg-10 mx-auto text-left text-md-center">
              <section
                dangerouslySetInnerHTML={{
                  __html: data?.PageDetails?.edges[0]?.node?.content,
                }}
              />
            </section>
          </Row>
        </Container>
      </section>
      <section className="countries-page col-12 col-lg-10 mx-auto">
      <CountriesGrid lang={lang} />
      </section>
      <section className="page-section">   
      <section className="cp-section col-12 col-lg-10 mx-auto">
        <Container> 
          <Row>
            <Col xs="12" lg="6" className="coverage__content">
                <section id="america" className="">
                    <h2>
                        <span dangerouslySetInnerHTML={{
                            __html: data?.OurCoverage?.edges[1]?.node?.title,}} />
                    </h2>
                    
                    <section
                        dangerouslySetInnerHTML={{
                        __html: data?.OurCoverage?.edges[1]?.node?.content,
                        }}  
                    />
                    
                </section>
            </Col>
            <Col className="d-none d-lg-flex col-lg-5 offset-lg-1">
                <img
                    src= {america}
                    alt="America"
                    loading="lazy"
                />
            </Col>
          </Row>
          </Container>
          </section>
          </section>
          <section className="page-section">
          <section className="cp-section col-12 col-lg-10 mx-auto">
          <Container>
          <Row>
            <Col xs="12" lg="6" className="coverage__content">
                <section id="africa" className="">
                <h2>
                    <span
                    dangerouslySetInnerHTML={{
                        __html: data?.OurCoverage?.edges[2]?.node?.title,
                    }}
                    />
                </h2>
                <section
                    dangerouslySetInnerHTML={{
                    __html: data?.OurCoverage?.edges[2]?.node?.content,
                    }}
                />
                </section>
            </Col>
            <Col className="d-none d-lg-flex col-lg-5 offset-lg-1">
                <img
                    src= {africa}
                    alt="Africa"
                    loading="lazy"
                />
            </Col>
          </Row>
          </Container>
        </section>
        </section>
          <section className="page-section">
        <section className="cp-section col-12 col-lg-10 mx-auto">
          <Container>
          <Row>
          <Col xs="12" lg="6" className="coverage__content">
            <section id="asia" className="">
              <h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.OurCoverage?.edges[0]?.node?.title,
                  }}
                />
              </h2>

              <section
                dangerouslySetInnerHTML={{
                  __html: data?.OurCoverage?.edges[0]?.node?.content,
                }}
              />
            </section>
            </Col>
              <Col className="d-none d-lg-flex col-lg-5 offset-lg-1">
                <img
                    src= {asia}
                    alt="Asia"
                    loading="lazy"
                />
                </Col>
          </Row>
        </Container> 
        </section>
      </section>
      <section className="cptools">
      <Tools  data={data?.HomeTools?.edges} />
      </section>

    </Layout>
  )
}

export default CountryProfilepage

export const query = graphql`
  query ($frontend_slug: String!, $language_code: String!) {
    PageDetails: allWpPage(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
      }
    ) {
      edges {
        node {
          id
          content
          fmmCore {
            languageCode
            frontendSlug
            seoDescription
            seoTitle 
          }
          hero {
            title
            linkUrl
            linkLabel
            intro
            image {
              sourceUrl
            }
          }
        }
      }
    }

    Languages: allWpPage(
      filter: { fmmCore: { frontendSlug: { eq: $frontend_slug } } }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug
          }
        }
      }
    }

    HomeTools: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }

      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "HomeTools" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
          generic_block_data {
            priorityOrder
            linkLabel
            priorityOrder
            linkTo {
              target
              title
              url
            }
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }


    OurCoverage: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "OurCoverage" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
          generic_block_data {
            priorityOrder
            linkLabel
            priorityOrder
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    FooterCopyright: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "FooterCopyright" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
